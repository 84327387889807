<template>
  <div>
    <h4 class="text-align-center mt-1">
      {{ $t('aws.connection-details') }}
    </h4>

    <el-collapse-transition>
      <div v-if="shouldShowWarning && isInitialized"
        class="local-message warning">
        <p>{{ $t(readonly ? 'aws.readonly-warning' : 'aws.edit-warning') }}</p>
        <div class="text-align-center">
          <el-button v-if="!readonly"
            @click="shouldShowWarning = false;">
            {{ $t('aws.warning-button') }}
          </el-button>
        </div>
      </div>
    </el-collapse-transition>

    <div v-loading="shouldShowWarning && !readonly"
      class="bs-border-box d-flex flex-justify-center flex-wrap"
      :element-loading-text="$t('aws.start-editing')">
      <!-- Left column -->
      <div class="content-column">
        <div>
          <p v-if="connectType === 'AWSHC' && isEditing"
            class="local-message warning">
            {{ $t('aws.no-edit-hosted') }}
          </p>

          <el-form-item v-if="connectType === 'AWS'"
            :prop="(isEditing ? 'bEndConfig' : 'serviceObj.bEnd') + '.partnerConfig.type'"
            :label-width="labelWidth">
            <template #label>
              <span class="capitalize-text">{{ $t('connections.type') }}</span>
            </template>
            <el-radio-group ref="peerGroup"
              v-model="config.type"
              name="peerGroup">
              <el-radio-button label="public">
                {{ $t('general.public') }}
              </el-radio-button>
              <el-radio-button label="private">
                {{ $t('general.private') }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-collapse-transition>
            <p v-if="isPublic"
              class="local-message info">
              {{ $t('aws.manual-intervention') }}
            </p>
          </el-collapse-transition>

          <el-form-item :prop="(isEditing ? 'bEndConfig' : 'serviceObj.bEnd') + '.partnerConfig.name'"
            :label-width="labelWidth"
            class="show-non-editable">
            <template #label>
              {{ $t('aws.connection-name') }}
              <el-tooltip placement="top"
                :content="$t('aws.connection-name-tooltip')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="config.name"
              type="text"
              :disabled="connectType === 'AWSHC' && isEditing"
              data-demo="Example AWS title"
              data-testid="awsConnectionName"
              name="awsConnectionName" />
          </el-form-item>

          <el-form-item :prop="(isEditing ? 'bEndConfig' : 'serviceObj.bEnd') + '.partnerConfig.ownerAccount'"
            class="show-non-editable"
            :label-width="labelWidth">
            <template #label>
              {{ $t('aws.account-id') }}
              <el-tooltip placement="top"
                :content="$t('aws.account-id-tooltip')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-autocomplete v-model="config.ownerAccount"
              type="text"
              :fetch-suggestions="awsAccounts"
              :disabled="connectType === 'AWSHC' && isEditing"
              data-demo="684021030471"
              data-testid="awsAccountId"
              name="awsAccountId"
              class="full-width"
              @blur="cleanAccountId" />
          </el-form-item>

          <el-form-item v-if="connectType === 'AWS'"
            :prop="(isEditing ? 'bEndConfig' : 'serviceObj.bEnd') + '.partnerConfig.asn'"
            :label-width="labelWidth">
            <template #label>
              {{ $t('connections.customer-asn') }}
              <el-tooltip placement="top"
                :content="asnDisabled ? $t('connections.asn-from-mcr') : $t('aws.bgp-asn')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="config.asn"
              type="text"
              :disabled="asnDisabled"
              data-demo="65333"
              data-testid="customerAsn"
              name="customerAsn" />
          </el-form-item>

          <el-collapse-transition>
            <el-form-item v-if="connectType === 'AWS' && isMcr && !isEditing && config.type === 'private'"
              prop="serviceObj.bEnd.partnerConfig.amazonAsn"
              :label-width="labelWidth">
              <template #label>
                {{ $t('aws.amazon-asn') }}
                <el-tooltip placement="top"
                  :content="$t('aws.default-amazon-asn')"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-input v-model="config.amazonAsn"
                type="text"
                data-demo="65333"
                data-testid="amazonAsn"
                name="amazonAsn" />
            </el-form-item>
          </el-collapse-transition>
        </div>
      </div>
      <!-- Right column -->
      <div v-if="connectType === 'AWS'"
        class="content-column">
        <div>
          <el-form-item :prop="(isEditing ? 'bEndConfig' : 'serviceObj.bEnd') + '.partnerConfig.authKey'"
            :label-width="labelWidth">
            <template #label>
              {{ $t('aws.bgp-key') }}
              <el-tooltip placement="top"
                :content="$t('aws.bgp-key-tooltip')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="config.authKey"
              type="text"
              data-testid="bgpAuthKey"
              name="bgpAuthKey" />
          </el-form-item>

          <el-form-item :prop="(isEditing ? 'bEndConfig' : 'serviceObj.bEnd') + '.partnerConfig.customerIpAddress'"
            :label="$t('connections.customer-ip')"
            :label-width="labelWidth"
            class="long-error">
            <el-input v-model="config.customerIpAddress"
              type="text"
              placeholder="e.g. 192.0.1.0/30"
              :data-demo="isPublic ? '191.0.2.0/24' : '172.0.0.1/30'"
              data-testid="customerIpAddress"
              name="customerIpAddress" />
          </el-form-item>

          <el-form-item :prop="(isEditing ? 'bEndConfig' : 'serviceObj.bEnd') + '.partnerConfig.amazonIpAddress'"
            :label="$t('connections.amazon-ip')"
            :label-width="labelWidth"
            class="long-error">
            <el-input v-model="config.amazonIpAddress"
              type="text"
              placeholder="e.g. 192.0.1.0/30"
              :data-demo="isPublic ? '191.0.2.0/24' : '172.0.0.1/30'"
              data-testid="amazonIpAddress"
              name="amazonIpAddress" />
          </el-form-item>

          <el-collapse-transition>
            <el-form-item v-if="isPublic"
              :prop="(isEditing ? 'bEndConfig' : 'serviceObj.bEnd') + '.partnerConfig.prefixes'"
              :label-width="labelWidth">
              <template #label>
                {{ $t('aws.amazon-prefixes') }}
                <el-tooltip placement="top"
                  :content="$t('aws.amazon-prefixes-tooltip')"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <el-input v-model="config.prefixes"
                type="text"
                data-demo="191.0.2.0/24,191.0.2.0/30"
                placeholder="e.g. 192.0.2.0/24,192.0.2.0/30"
                :disabled="config.type === 'public' && isEditing"
                name="prefixes" />
            </el-form-item>
          </el-collapse-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MEGAPORT_ASN } from '@/Globals.js'

export default {
  name: 'AWSConfig',

  props: {
    value: {
      type: Object,
      required: true,
    },
    isMcr: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
    aEndConnection: {
      type: Object,
      required: false,
      default: undefined,
    },
    connectType: {
      type: String,
      required: true,
    },
    connectionName: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ['input', 'edited'],

  data() {
    return {
      labelWidth: '245px',
      vlan: 0,
      // Initalize AWS config form fields
      config: {
        type: this.connectType === 'AWS' ? 'private' : null,
        name: null,
        asn: null,
        ownerAccount: null,
        customerIpAddress: null,
        amazonIpAddress: null,
        amazonAsn: null,
        authKey: null,
        prefixes: null,
      },
      shouldShowWarning: true,
      isInitialized: false,
    }
  },

  computed: {
    /**
     * Determine if form is complete
     */
    isComplete() {
      if (this.connectType === 'AWSHC') {
        return !!(this.config.name && this.config.ownerAccount)
      }

      if (this.config.type === 'public' && !this.isMcr) {
        return !!(this.config.name && this.config.ownerAccount && this.config.customerIpAddress && this.config.amazonIpAddress)
      }

      if (this.config.type === 'public') {
        return !!(this.config.name && this.config.ownerAccount)
      }

      if (this.config.type === 'private' && this.isMcr) {
        return !!(this.config.name && this.config.ownerAccount)
      }

      if (this.config.type === 'private') {
        return !!(this.config.name && this.config.asn && this.config.ownerAccount)
      }

      return false
    },
    isPublic() {
      return this.config.type === 'public'
    },
    styles() {
      if (!this.showWarning) return false

      return {
        opacity: '0.5',
        position: 'relative',
        'pointer-events': 'none',
      }
    },
    asnDisabled() {
      return typeof this.aEndConnection !== 'undefined' && this.isMcr
    },
  },

  watch: {
    config: {
      handler() {
        if (this.config.type === 'private') {
          this.config.prefixes = null
        }
        // Emit every time config object is changed
        this.emitUpdatedPartnerConfig()
      },
      deep: true,
    },
  },

  created() {
    if (this.value.vlan) {
      // This does not make sense. If the value === 0 (a legit value) it will fail. @todo
      this.vlan = this.value.vlan
    }
    if (this.value.partnerConfig) {
      // This strips out all the keys we are not interested in here and only picks the ones we are interested in
      // for this editing screen. Since this is emitted on the v-model, it automatically strips out the "isLive"
      // key, which means that we can pick that up before submitting.
      Object.keys(this.value.partnerConfig).forEach(key => {
        if (typeof this.config[key] !== 'undefined' && key !== 'complete') {
          this.config[key] = this.value.partnerConfig[key]
        }
      })
      if (typeof this.config.amazonAsn === 'number') {
        this.config.amazonAsn = this.config.amazonAsn.toString()
      }
      if (!this.config.name && this.connectionName) {
        this.config.name = this.connectionName
      }
      this.emitUpdatedPartnerConfig()
      if (this.asnDisabled) {
        this.autoFillFields()
      }
    }
  },

  mounted() {
    if (this.connectType === 'AWSHC') {
      this.shouldShowWarning = false
    } else if (this.$refs.peerGroup && this.$refs.peerGroup.elFormItem) {
      this.shouldShowWarning = (this.$refs.peerGroup.elFormItem.elForm || {}).disabled || this.showWarning
    } else {
      this.shouldShowWarning = this.showWarning
    }
    this.isInitialized = true
  },

  methods: {
    /**
     * Retrieve a list of suggested account numbers for the user based on any AWS connections they already
     * have (either designed or deployed). This is used to present a list of account numbers to the user which
     * they can select from or they can type a different one. The returned list is filtered based on what they
     * have already entered.
     *
     * See the ElAutocomplete documentation for further information.
     *
     * @param queryString {string} The value already entered in the field
     * @param callback {function} A callback function to call once we have the required information
     */
    awsAccounts(queryString, callback) {
      const connections = this.$store.getters['Services/myConnections']
      const awsAccounts = new Set()
      for (const connection of connections) {
        let awsConnection = null
        if (connection.resources?.csp_connection) {
          // If it's on an MCR it'll be an array, so we need to find the correct one.
          if (Array.isArray(connection.resources.csp_connection)) {
            awsConnection = connection.resources.csp_connection.find(csp => ['AWS', 'AWSHC'].includes(csp.connectType))
          } else if (['AWS', 'AWSHC'].includes(connection.resources.csp_connection.connectType)) {
            awsConnection = connection.resources.csp_connection
          }
        }

        if (awsConnection?.ownerAccount) {
          // Pick up deployed connections
          awsAccounts.add(awsConnection.ownerAccount)
        } else if (
          connection.provisioningStatus === this.G_PROVISIONING_DESIGN &&
          (connection.connectType === 'AWS' || connection.connectType === 'AWSHC') &&
          connection.bEnd?.partnerConfig?.ownerAccount
        ) {
          // Pick up designed connections
          awsAccounts.add(connection.bEnd.partnerConfig.ownerAccount)
        }
      }
      const matchingAccounts = queryString ? [...awsAccounts].filter(account => account.indexOf(queryString) === 0) : [...awsAccounts]
      callback(matchingAccounts.map(item => {
        return { value: item }
      }))
    },
    /**
     * Emit partnerConfig details back to CreateConnection
     */
    emitUpdatedPartnerConfig() {
      if (this.shouldShowWarning) {
        this.$emit('input', this.value)
        return
      }

      if (this.config.type !== 'private' && this.config.amazonAsn) {
        delete this.config.amazonAsn
      }

      this.$emit('input', {
        vlan: this.vlan,
        productUid: this.value.productUid,

        partnerConfig: Object.assign({}, this.config, {
          connectType: this.connectType,
          complete: this.isComplete,
        }),
      })
      this.$emit('edited')
    },
    // Remove any non digit values from the AWS account Id
    cleanAccountId() {
      this.config.ownerAccount = this.config.ownerAccount?.replace(/[^0-9]+/g, '')
    },
    /**
     * Autofill asn field when using MCR
     */
    autoFillFields() {
      if (Object.prototype.hasOwnProperty.call(this.aEndConnection, 'resources')) {
        this.config.asn = this.aEndConnection.resources.virtual_router.mcrAsn.toString()
      } else if (Object.prototype.hasOwnProperty.call(this.aEndConnection, 'config')) {
        this.config.asn = this.aEndConnection.config.mcrAsn?.toString() || MEGAPORT_ASN.toString()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.local-message {
  margin: 1rem;
  padding: 2rem;
  border-radius: var(--border-radius-base);
  &.warning {
    border: 1px solid var(--color-warning);
    background-color: var(--color-warning-light-9);
  }
  &.info {
    border: 1px solid var(--color-info);
    background-color: var(--color-info-light-9);
  }
}

.content-column {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  width: min-content;
  min-width: 510px;
}
.content-column > div {
  flex: 1 1 auto;
}
</style>

import { render, staticRenderFns } from "./SfdcConfig.vue?vue&type=template&id=499f0baa&scoped=true"
import script from "./SfdcConfig.vue?vue&type=script&lang=js"
export * from "./SfdcConfig.vue?vue&type=script&lang=js"
import style0 from "./SfdcConfig.vue?vue&type=style&index=0&id=499f0baa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "499f0baa",
  null
  
)

export default component.exports
<template>
  <el-form-item :prop="validationKey"
    :label-width="labelWidth">
    <template #label>
      {{ $t('connections.mac-address') }}
      <el-tooltip placement="top"
        :content="$t('connections.mac-address-format')"
        :open-delay="500">
        <i class="fas fa-question-circle color-info popover-info-icon"
          aria-hidden="true" />
      </el-tooltip>
    </template>
    <el-input v-model="macAddress"
      type="text"
      data-name="macAddress"
      data-testid="mac-address"
      data-demo="00:01:00:01:12:34"
      autocomplete="off" />
  </el-form-item>
</template>

<script>
import { validateMacAddress } from '@/validators.js'

export default {
  name: 'MacAddress',

  props: {
    validationKey: {
      type: String,
      required: true,
      default: null,
    },
    labelWidth: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      macAddress: this.value,
    }
  },

  watch: {
    macAddress(value) {
      this.macAddress = value
      this.$emit('input', this.macAddress || null)
    },
  },
}

export const macAddressValidationRule = (key, required, trigger) => ({
  [key]: { required, trigger, validator: validateMacAddress },
})
</script>

<template>
  <section data-name="sdf-config">
    <h4 class="text-align-center mt-1 mb-2">
      {{ $t('salesforce.configuration') }}
    </h4>

    <div class="info-bar">
      <em>{{ $t('general.note') }}:</em> {{ $t('salesforce.approvals') }}
    </div>

    <el-form-item :prop="(isEditing ? 'bEndConfig' : 'serviceObj.bEnd') + '.partnerConfig.asn'"
      :label-width="labelWidth">
      <template #label>
        {{ $t('connections.asn') }}
        <el-tooltip placement="top"
          :content="isMcr ? $t('connections.asn-from-mcr') : $t('aws.bgp-asn')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input v-model="config.asn"
        type="text"
        data-demo="65333"
        :disabled="isMcr"
        name="asn"
        data-testid="asn" />
    </el-form-item>

    <el-form-item :label-width="labelWidth">
      <template #label>
        {{ $t('connections.bgp-password') }}
        <el-tooltip placement="top"
          :content="$t('salesforce.provide-password')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input v-model="config.password"
        type="text"
        name="password"
        data-testid="bgp-password" />
    </el-form-item>

    <el-form-item :prop="(isEditing ? 'bEndConfig' : 'serviceObj.bEnd') + '.partnerConfig.prefixes'"
      :label-width="labelWidth">
      <template #label>
        {{ $t('salesforce.prefixes') }}
        <el-tooltip placement="top"
          :content="$t('salesforce.prefixes-tooltip')"
          :open-delay="500">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>
      <el-input v-model="config.prefixes"
        type="text"
        data-demo="191.0.2.0/24,191.0.2.0/30"
        placeholder="e.g. 192.0.2.0/24,192.0.2.0/30"
        name="prefixes"
        data-testid="prefixes" />
    </el-form-item>
  </section>
</template>

<script>
import { MEGAPORT_ASN } from '@/Globals.js'

export default {
  name: 'SfdcConfig',

  props: {
    value: {
      type: Object,
      required: true,
    },
    aEndConnection: {
      type: Object,
      default: null,
    },
    isMcr: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['input'],

  data() {
    return {
      labelWidth: '200px',
      vlan: 0,
      config: {
        asn: null,
        password: null,
        prefixes: null,
      },
    }
  },

  computed: {
    /**
     * Determine if form is complete
     */
    isComplete() {
      return !!this.config.asn
    },
  },

  watch: {
    config: {
      handler() {
        // Emit every time config object has changed
        this.emitUpdatedPartnerConfig()
      },
      deep: true,
    },
  },

  created() {
    if (this.value.partnerConfig) {
      // This strips out all the keys we are not interested in here and only picks the ones we are interested in
      // for this editing screen. Since this is emitted on the v-model, it automatically strips out the "isLive"
      // key, which means that we can pick that up before submitting.
      Object.keys(this.value.partnerConfig).forEach(key => {
        if (typeof this.config[key] !== 'undefined' && key !== 'complete') {
          this.config[key] = this.value.partnerConfig[key]
        }
      })
      this.emitUpdatedPartnerConfig()
      this.autoFillFields()
    }
  },

  methods: {
    /**
     * Emit partnerConfig details back to CreateConnection
     */
    emitUpdatedPartnerConfig() {
      this.$emit('input', {
        vlan: null,
        productUid: this.value.productUid,
        partnerConfig: Object.assign({}, this.config, {
          connectType: 'SFDC',
          complete: this.isComplete,
        }),
      })
    },
    /**
     * Autofill fields
     */
    autoFillFields() {
      if (this.isMcr && Object.prototype.hasOwnProperty.call(this.aEndConnection, 'resources')) {
        this.config.asn = this.aEndConnection.resources.virtual_router.mcrAsn.toString()
      } else if (this.isMcr && Object.prototype.hasOwnProperty.call(this.aEndConnection, 'config')) {
        this.config.asn = this.aEndConnection.config.mcrAsn?.toString() || MEGAPORT_ASN.toString()
      }
      if (Array.isArray(this.value.partnerConfig.prefixes)) {
        this.config.prefixes = this.value.partnerConfig.prefixes.toString()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.info-bar {
  background-color: var(--color-white);
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 2rem;
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 2rem;
  line-height: normal;
}
</style>

<template>
  <section class="flex-row-centered flex-wrap">
    <end-details-card :loading="leftLoading"
      :active="leftActive"
      :disabled="leftDisabled"
      :left-icon-id="leftLeftIconId"
      :detail-lines="leftDetailLines"
      :diversity-zone="leftDiversityZone"
      :right-image-src="leftRightImageSrc">
      <template #extraContent>
        <slot name="leftExtraContent" />
      </template>
    </end-details-card>

    <mu-mega-icon icon="Transfer"
      class="icon transfer" />

    <end-details-card :loading="rightLoading"
      :active="rightActive"
      :disabled="rightDisabled"
      :left-icon-id="rightLeftIconId"
      :detail-lines="rightDetailLines"
      :diversity-zone="rightDiversityZone"
      :right-image-src="rightRightImageSrc">
      <template #extraContent>
        <slot name="rightExtraContent" />
      </template>
    </end-details-card>
  </section>
</template>

<script>
import EndDetailsCard from '@/components/ui-components/EndDetailsCard.vue'

export default {
  name: 'ConnectionDetailsCards',
  components: {
    'end-details-card': EndDetailsCard,
  },
  props: {
    /**
     * Will display a loading spinner on the left card
     */
    leftLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Will display the left card highlighted
     */
    leftActive: {
      type: Boolean,
      default: false,
    },
    /**
     * Will display the left card grayed out
     */
    leftDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The name of the mega-icon to use for the left card
     */
    leftLeftIconId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * The detail lines to display on the centre of the panel. Plain text. First
     * line will be darker colour. If you want any additional content on the left
     * panel, including formatted HTML content, use the leftExtraContent slot. Note
     * that the content for the slot needs to have each line of the content within
     * a div in order for the formatting to be applied.
     */
    leftDetailLines: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    /**
     * Which diversity zone to display on the left details, if any
     */
    leftDiversityZone: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Image to display on the left hand side
     */
    leftRightImageSrc: {
      type: String,
      required: false,
      default: null,
    },

    /**
     * Will display a loading spinner on the right card
     */
    rightLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Will display the right card highlighted
     */
    rightActive: {
      type: Boolean,
      default: false,
    },
    /**
     * Will display the right card grayed out
     */
    rightDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The name of the mega-icon to use for the right card
     */
    rightLeftIconId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * The detail lines to display on the centre of the panel. Plain text. First
     * line will be darker colour. If you want any additional content on the right
     * panel, including formatted HTML content, use the rightExtraContent slot. Note
     * that the content for the slot needs to have each line of the content within
     * a div in order for the formatting to be applied.
     */
    rightDetailLines: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    /**
     * Which diversity zone to display on the right details, if any
     */
    rightDiversityZone: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Image to display on the right hand side
     */
    rightRightImageSrc: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5em;
  height: 2.5em;
  fill: currentColor;
  &.transfer {
    margin: 1.5rem;
  }
}
</style>
